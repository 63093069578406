<template>
  <div >
    <img src="http://47.106.201.148:2346/groupweb/getPic?id=88" alt="" style="width:100%">
      <div class='xieyi_div' >
        <div class='look_g'>
            <div></div>收集个人资料声明
        </div>
        <div class="fonts look_l">{{ params.inforcollectpage_text1 }}</div>
        <div v-for='(v,i) in params.inforCollectList2' :key="i">
            <div v-if="v.jibie == 1">
                <pre class="fonts look_l" v-if="v.title" style="color: #333;font-weight: 600;margin: 0.5rem 0px ;display: flex;">
                    <span style="width: 30px;text-align: left;flex-shrink: 0;" v-if="v.titlenum">{{v.titlenum}}</span>
                    <span style="width: 92%;">{{v.title}}</span>
                </pre>
            </div>
            <div v-if="v.jibie == 2">
                <pre class="fonts look_l" v-if="v.title" style="color: #333;margin: 0.5rem 0 0.5rem 30px ;display: flex;">
                    <span style="width: 25px;text-align: left;" v-if="v.titlenum">{{v.titlenum}}</span>
                    <span style="width: 92%;">{{v.title}}</span>
                </pre>
            </div>
        </div>
        <div style="color: #333; font-weight: 600; margin-top: 1rem;font-family: Roboto,Microsoft JhengHei,'sans-serif' !important;">{{params.inforcollectpage_updatetime}}</div>
      </div>
    <div style="height: 2rem;"></div>
    <cookies></cookies>
  </div>
</template>

<script>
  import cookies from '../cookies/cookie.vue'
  export default {
    components: {
      cookies,
    },
      data() {
          return{
            params: {
                "lan": "big5",
                "picurl": "http://47.106.201.148:2346/groupweb/getPic?id=88",
                "inforcollectpage_text1": "铧亚科技有限公司（下称“铧亚科技”）一贯注重客户（下称“客户”）的个人隐私。客户在使用铧亚科技所提供的产品或服务期间，铧亚科技可能会不时或/及定时收集和使用客户的相关资讯。客户使用或继续使用铧亚科技的产品或服务，即代表客户允许铧亚科技按照铧亚科技的《私隐政策》用作收集、使用及储存客户的相关资讯",
                "inforcollectpage_updatetime": "更新日期 : 2022年6月",
                "inforCollectList2": [
                    {
                        "titlenum": "1 / ",
                        "boldFlag": false,
                        "jibie": 1,
                        "id": 1,
                        "title": "可能收集的信息"
                    },
                    {
                        "titlenum": null,
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 4,
                        "title": "铧亚科技可能会不时或/及定时收集客户的个人信息，例如电话号码、身份证明、网络身份标识信息（其包括系统账号、IP位址、电子邮箱地址及与前述有关的密码、口令、口令保护答案等）、日志信息（其包括使用铧亚科技所提供的产品或/及服务期间的浏览记录、使用记录、点击记录、操作日志等）、设备信息（其包括设备型号、设备MAC位址、操作系统类型、设备设置等）、软件列表唯一设备识别码（如IMEI / Android ID / IDFA / OPENUDID / GUID在内的描述个人常用设备基本情况的信息等）或/及位置信息（其包括精准定位信息、住宿信息等）。"
                    },
                    {
                        "titlenum": "2 / ",
                        "boldFlag": false,
                        "jibie": 1,
                        "id": 5,
                        "title": "如何收集及使用信息"
                    },
                    {
                        "titlenum": null,
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 6,
                        "title": "铧亚科技可能在客户注册成为铧亚科技的帐户、用户或/及在使用铧亚科技所提供的其他服务过程中向客户收集上述信息（部分或全部）。铧亚科技收集到的信息将会用作下列用途："
                    },
                    {
                        "titlenum": "•",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 7,
                        "title": "帮助客户完成注册程序；"
                    },
                    {
                        "titlenum": "•",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 8,
                        "title": "向客户提供商品或服务期间，用作身份验证、客户服务、安全防范、诈骗监测、存盘及备份用途；"
                    },
                    {
                        "titlenum": "•",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 9,
                        "title": "协助铧亚科技可以针对性地响应客户的个人化需求，改善铧亚科技的产品及服务；"
                    },
                    {
                        "titlenum": "•",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 10,
                        "title": "为客户提供针对性的推送消息，让客户获得更优质的产品及服务；"
                    },
                    {
                        "titlenum": "•",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 11,
                        "title": "维护及开发移动通信的业务系统及基础设施，包括测试及更新该等系统；及"
                    },
                    {
                        "titlenum": "•",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 12,
                        "title": "让客户能够参与有关铧亚科技产品及服务调查。"
                    },
                    {
                        "titlenum": null,
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 13,
                        "title": "除下列必要的情况外，铧亚科技未经客户同意绝对不会与任何铧亚科技的服务供应商（下称“服务供应商”）分享客户的个人信息 :"
                    },
                    {
                        "titlenum": "•",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 14,
                        "title": "遵守适用的法律及法规；"
                    },
                    {
                        "titlenum": "•",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 15,
                        "title": "遵守法院命令或其他法律规定；"
                    },
                    {
                        "titlenum": "•",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 16,
                        "title": "遵守相关政府机关的要求；及"
                    },
                    {
                        "titlenum": "•",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 17,
                        "title": "只有透露客户的信息（如客户的IDFA、网络身份标识符、IP位址、IMEI、MAC位址等相关资讯），才能提供客户所要求服务供应商的产品及服务，或用于铧亚科技进行数据分析或研究、改善铧亚科技的产品及服务。"
                    },
                    {
                        "titlenum": null,
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 18,
                        "title": "目前铧亚科技所接入的服务供应商SDK，其服务主要包括：进行用户行为的数据上报分析、进行程序crash的信息上报、进行性能统计、提供浏览器服务、用于广告统计及监控、用于服务供应商帐户登录、用于便利客户分享内容至服务供应商的平台。客户须留意，该等接入服务供应商的服务由相关服务供应商负责营运，须受该服务供应商本身的服务条款及信息声明（而非铧亚科技的《私隐政策》）条款所约束。"
                    },
                    {
                        "titlenum": "3 / ",
                        "boldFlag": false,
                        "jibie": 1,
                        "id": 19,
                        "title": "信息安全"
                    },
                    {
                        "titlenum": null,
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 20,
                        "title": "铧亚科技使用各种安全技术及程序，以防信息遗失、不当使用、未经授权阅览或披露。如在某些服务中，铧亚科技将利用加密技术（SSL）来保护客户所提供的个人信息。但由于技术限制以及可能存在的各种恶意手段，在互联网行业中，即使铧亚科技竭尽所能加强安全措施，亦无法保证客户信息能够百分百安全。因此客户需要了解，客户接入铧亚科技服务所提供的系统及通信网络期间，也有可能因铧亚科技在可控范围外的因素而出现问题。"
                    },
                    {
                        "titlenum": "4 / ",
                        "boldFlag": false,
                        "jibie": 1,
                        "id": 21,
                        "title": "适用范围"
                    },
                    {
                        "titlenum": "(a)",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 22,
                        "title": "适用范围在客户注册本应用帐号时，客户须根据本应用帐号要求而提供个人信息；"
                    },
                    {
                        "titlenum": "(b)",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 23,
                        "title": "在客户使用本应用网络服务，或到访本应用平台网页期间，本应用网络服务及网页会自动收集并记录有关客户在浏览器及电脑上所提供的信息，包括但不限于客户的IP位址、浏览器类型、使用语言、到访日期及时间、软硬件特征信息及客户需求网页记录等数据；及"
                    },
                    {
                        "titlenum": "(c)",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 24,
                        "title": "本应用网络服务及网页通过合法途径从服务供应商收集使用者的个人信息。"
                    },
                    {
                        "titlenum": null,
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 25,
                        "title": "客户了解并同意，以下信息不适用于铧亚科技的《私隐政策》："
                    },
                    {
                        "titlenum": "(a)",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 26,
                        "title": "客户在使用本应用网络服务及网页所提供的搜索服务期间所输入的关键字信息；"
                    },
                    {
                        "titlenum": "(b)",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 27,
                        "title": "本应用网络服务及网页所收集到客户在其发布的有关信息，包括但不限于参与活动、成交信息及评价详情；及"
                    },
                    {
                        "titlenum": "(c)",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 28,
                        "title": "违反法律规定或违反本应用网络服务及网页规则行为及本应用网络服务及网页已对客户所采取的措施。"
                    },
                    {
                        "titlenum": null,
                        "boldFlag": false,
                        "jibie": 1,
                        "id": 29,
                        "title": "Cookie的使用"
                    },
                    {
                        "titlenum": "(a)",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 30,
                        "title": "在客户没有拒绝接受使用cookie的情况下，本应用网络服务及网页会在客户的电话上设定或使用cookie，以便客户能登录或使用依赖于使用cookie的本应用平台服务或功能。本应用网络服务及网页使用cookie可为客户提供更优质的个人化服务，包括推广服务；"
                    },
                    {
                        "titlenum": "(b)",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 31,
                        "title": "客户有权选择接受或拒绝接受使用cookie。客户可以透过修改浏览器设置的方式拒绝接受使用cookie。若客户选择拒绝接受使用cookie，则客户可能无法登入或使用依赖于使用cookie的本应用网络服务或功能；及"
                    },
                    {
                        "titlenum": "(c)",
                        "boldFlag": false,
                        "jibie": 2,
                        "id": 32,
                        "title": "透过本应用网络服务及网页所设使用cookie所取得的有关信息，将适用于铧亚科技的《私隐政策》。"
                    }
                ],
                "qmiscode": 1000,
                "qmismsg": "success"
            }
          }
      },
      mounted() {
      
      },
      methods: {
        
      }
      
  }
</script>

<style >
@media (min-width: 767px) and (max-width: 1007.98px) {
     .xieyi_div{
      width: 90% !important;
    }
}
@media only screen and (max-width: 767px) {
     .xieyi_div{
        width: 90% !important;
      }
}
.xieyi_div{
    width: 60%;
    margin: auto;
    font-size: 16px;
    color: #333333;
  }
  .xieyi_div pre{
   border: none;
   padding: 0;
   font-size: 16px;
   line-height: 1.5;
   word-break:normal;
  }
  .look_g{
    font-size: 18px;
    margin-bottom: 1rem;
    color: #09666e;
    font-weight: 600;
    margin-top: 2rem;
  }
  .look_g div{
    display: inline-block;
    width: 1px;
    height: 15px;
    position: relative;
    top: 0.1rem;
    margin-right: 0.5rem;
    border-left: 3px solid #09666e;
  }
  .fonts{
    white-space: pre-wrap;
    font-family: "\5FAE\8EDF\6B63\9ED1\9AD4, sans-serif";
    margin-bottom: 0.2rem;
  }
</style>